import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import './css/Sing.css';
import $ from 'jquery';
// Import image We Can't Wait_Holder.png
import ReactPlayer from 'react-player';
import { useCallback } from 'react';


import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
// import ReactPlayer from 'react-player';
import MobileBannerImage from '../images/cleanLogo.png';
import RaysOnly from '../images/RaysOnly.png';
import KellyImage from '../images/Kelly.png';
import FooterImage from '../images/FooterImage.png';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FooterText from './FooterText';
import PraiseNav from './PraiseNav';
function Sing() {
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get('email');
  let schoolEmail;
  const MySwal = withReactContent(Swal);
  const [emailSent, setEmailSent] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  
  const sendEmail = useCallback(async (email) => {
    if (emailSent) {
      return; // Email already sent, skip further execution
    }
    const emailToUse = email || schoolEmail; // If email is provided, use it; otherwise, use schoolEmail
    if (emailToUse) {
      // You can customize the SweetAlert modal here
      const elements = document.getElementsByClassName("to-remove");
                            while (elements.length > 0) {
                                elements[0].parentNode.removeChild(elements[0]);
                            }
                            Swal.fire({
                              title: 'Loading',
                              text: 'Please wait for your email address to be verified...',
                              allowOutsideClick: false, // Prevent clicking outside to close
                              showConfirmButton: false // Remove the "Okay" button
                          });
                          
    }
    const response = await axios.get("/api/verifyEmail", {
        params: { schoolEmail: emailToUse, month: "february2025" },
    })
        .then((response) => {
            console.log(response);
            if (response.data === "False") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    html: 'Your school has not been registered, please register or ' +
                        '<a href="/contact">contact us</a>. '
                }).then (() => {
                  // Go to /sing
                  window.location.href = "/sing";
                });
            } else {
                MySwal.close();
                Swal.fire({
                  title: "Welcome to Praise-along!",
                    html: "Our monthly songs are free to sing in assemblies. Please remember to log projections and any copies made on your school’s CCLI copy report.",
                    input: 'checkbox',
                    inputPlaceholder: 'OK',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value) {
                            Swal.fire({ icon: 'success', text: 'Thank you for confirming!' });
                            setEmailConfirmed(true);
                            setVideoUrl(response.data[0]);
                            // Remove all elements where class is to-remove
                            const elements = document.getElementsByClassName("to-remove");
                            while (elements.length > 0) {
                                elements[0].parentNode.removeChild(elements[0]);
                            }
                        } else {
                            Swal.fire({ icon: 'error', text: "Please agree to the CCLI terms" }).then((result) => {
                              if (result.isConfirmed) {
                                // Reload page
                                window.location.reload();
                              }
                            });
                        }
                    } else {
                        console.log(`modal was dismissed by ${result.dismiss}`);
                    }
                });
                setEmailSent(true);
            }
        })
        .catch((err) => {
            console.log(err);
        });

    console.log(response);
}, [MySwal, schoolEmail, emailSent]);
// const printLyrics = () => {
//   const pdfUrl = "https://praisealongpublic.blob.core.windows.net/praisealongpublic/Moving Along.pdf";

//   // Open the PDF in a new window
//   window.open(pdfUrl, "_blank");
// };


// const printLyricsThree = () => {
//   const pdfUrl = "https://praisealongpublic.blob.core.windows.net/praisealongpublic/In the Spring.pdf";

//   // Open the PDF in a new window
//   window.open(pdfUrl, "_blank");
// };
  const register = (e) => {

    let inputtedSchool = $("#formBasicSchoolName").val();
    let inputtedEmail = $("#formBasicSchoolEmail").val();
    let inputtedDiocese = $("#formBasicDiocese").val();
    var checkedValue = $('#registerCheckbox').is(':checked');
    var checkedCCLI = true;
    
    console.log(checkedValue);
   console.log(inputtedDiocese);
  //  Check if school email ends with .sch.uk
  if(inputtedEmail.endsWith('.sch.uk')){
    
    //  Check if hool name is not empty
    if(inputtedSchool !== ""){
      // Check if diocese is not empty
      if(inputtedDiocese !== null){
        // Check if Privacy Policy is checked
        if(checkedValue === true){
          if(checkedCCLI === true){
            let stringtoFetch = '/api/message?diocese="nodiocese"&email=' + inputtedEmail+'&school=' + inputtedSchool+'&requestType=register';
            fetch(stringtoFetch)
      .then(response => response.json())
      .then(data => 
        console.log(data)
        );
      Swal.fire(
        'Registration Received!',
        'Thank you for your message, we\'ll be in touch. Please make sure you check your junk email.',
        'success'
      )
          }
          else {
            Swal.fire({
              icon: 'info',
              title: 'Oops...',
              html: 'You need a Collective worship licence from CCLI to be able to use our free resources. <a href="https://uk.ccli.com/copyright-licences/#school-licences" target="_blank">Get help here</a>.',
            });
          }
          
          // Check if CCLI is checked
          
          
      

          
          
        }
        else{
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: 'Please agree to our Privacy Policy',
          })
        }
      }
      else{
        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: 'Please enter your diocese',
        })
      }
    }
    else{
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'Please enter your school name',
      })
    }
  }
  else{
    Swal.fire({
      icon: 'info',
      title: 'Oops...',
      html: 'Please enter a valid school email address (sch.uk) <br />If you don\'t have one, and would like access to our monthly songs, you can contact us <a href="/contact">here</a>',
    })
  }
   
   
    e.preventDefault();
  }
  const [videoUrl, setVideoUrl] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  
  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sendEmail, emailParam]);  // Include sendEmail in the dependency array

  
  
  // Get the email url param
  
  
  useEffect(() => {
    if (emailParam) {
      // If email param is provided, send it to the API
      sendEmail(emailParam);
    }
  }
  , [emailParam, sendEmail]);
  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
    

      <div className="gradient-background"></div>
      <div className="navbar-container">
      <PraiseNav />

      </div>
      <a href="/" className="text-decoration-none">

      <img src={bannerImage} alt="New sing-along" className="banner-image mt-5 mb-5" />
      </a>

        <div className="row">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
            

            {videoUrl !== "" ? (
              
// Div text align center
<div className="text-center">

    {/* <img src={HolderImage} alt="Holder" className="mb-5" width="100%" /> */}

    {videoUrl.endsWith(".mp4") ? (
      <ReactPlayer url={videoUrl} controls={true} width="100%" height="auto" className="mb-5" />
    ) : null}
    {videoUrl.endsWith(".png") ? (
      <img src={videoUrl} alt="Promo Page" className="landing-page-image mb-5"  style={{width:"100%"}}/>
    ) : null}
          

    <div className="row homepage-content ">
     <div className="col"></div>
     <div className="" >
    
              
     {/* <span className="about-header">
     LEADER'S NOTES FOR COLLECTIVE WORSHIP:<br/><span style={{fontSize: "14px"}}>By Kelly Fort</span> </span>
      */}
      <span className="landing-header-large"><b>LEADERS' NOTES</b></span><br/>
      <span className="" style={{color:"rgb(212, 137, 185)"}}>For teachers and children who lead worship.</span>
     <br /><br /><span className="about-content" style={{lineHeight:"20px"}}>
     February is the month when St Valentine’s Day is celebrated around the world, as people send flowers and greetings cards to express their love for one another.  
<br/><br/>
{/* Verses about spring can be found throughout the Bible. Here is a great example: <br /><br /> */}
{/* <a href="https://www.biblegateway.com/passage/?search=Song+of+Solomon+2:11-12&version=GNT" target='blank'>Song of Solomon 2: 11-12(Good News Translation)</a><br/><br/> */}
This is usually a romantic kind of love for couples, but there are other kinds of love too! <br /><br />
There’s the love we feel for our brothers, sisters and close friends; the love that parents feel for their children; we can even feel love and compassion in our hearts for human beings that we have never even met!<br/><br/>
The words “I love you” are easy to say but we can only really show true love by the way we treat one another. <br/><br/>
Our song this month provides us with a useful checklist to help us understand what true love is like. It is based on a famous Bible reading, often read at Christian weddings! <br/><br/>
We can read it in the New Testament, in 1Corinthians, Chapter 13, Verses 4 - 7:  <br/><br/>

<a href="https://www.biblegateway.com/passage/?search=1%20Corinthians%2013:4-7&version=NIV" target="_blank" rel="noopener noreferrer"><b>Bible Reading</b></a>
<br /><br />

<span className="" style={{color:"rgb(212, 137, 185)"}}> <b>Reflection</b>:</span><br/><br/>
<span style={{display: "block", marginBottom: "1rem"}}>What characteristics of love can we remember from the Bible reading? </span>
<span style={{display: "block", marginBottom: "1rem"}}>How might we share this kind of love with the people in our lives?</span>
</span>
              
              {/* <div className="text-center"> */}
    {/* <ReactPlayer url="https://praisealongpublic.blob.core.windows.net/praisealongpublic/H&F Play Appeal_2.mp4" controls={true} width="100%" height="auto" className="mb-5" />  */}
    {/* </div> */}
              
     </div>
   </div>  
      
 
   

</div>
  
  

) : null}




            </div>
            <div className="col"></div>
            {emailConfirmed && (
               <div className="row homepage-content trans-white-bg pt-5 pb-5 mt-5">
               <div className="col"></div>
               <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
                
               <b style={{ color: 'rgb(212, 24, 118)', fontSize: '24px' }} className="keep-and-collect">KEEP AND COLLECT SONG PACKS</b><br/><br/>
          <a href="/contact"><b>Contact us</b></a> to order the "What Love Is" digital song pack for <span style={{color:"rgb(212, 137, 185)"}}>£6</span><br/><br/>
          <span style={{color:"rgb(212, 137, 185)"}}>Includes:</span> Lyric sheet, vocal and piano scores, chords, melody guide track 
          (mp3) professional backing track (mp3) and licensing information.<br/><br />
                
               
              
                
               </div>
               <div className="col " ></div>
             </div>  
            )}
           {emailConfirmed && (
 <div className="row homepage-content pt-5 pb-5">
 <div className="col"></div>
 <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
  
 <b>About the Composer</b><br/>
<div style={{fontSize:"15px", marginTop: "10px"}}>
<p>Hi I’m Kelly. I'm a songwriter! I have contributed to lots of popular and 
award-winning assembly <a href="https://www.outoftheark.co.uk/products/writer/kelly-fort/">songbooks</a> and have also worked in schools and 
churches for many years, helping to lead collective worship and singing 
assemblies.</p>
</div>
<img src={KellyImage} alt="REAccredited" class="img-fluid" />
<div style={{fontSize:"15px"}} className="mt-4">
<p>I love writing for collective worship because it helps people to identify 
shared values and invites us to respond in our own hearts to God’s 
unconditional love for us all.
<br/><br/>
Recently, I have started releasing my music independently. I hope you like it 
and welcome any feedback you can offer. Feel free to get in touch with me 
at <a href="mailto:info@praise-along.com">info@praise-along.com</a></p>
</div>
  
 </div>
 <div className="col " ></div>
</div>  
           )}
   
        </div>


       
<div className="row to-remove">
            <div className="col"></div>
            <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0 mt-5 mb-5"  id="registerScrollTo">
              <div className="register-header">
                REGISTER
              </div>
              <div className="register-content">
              Join our network of registered schools to access free monthly song videos and/or purchase sheet music and backing tracks for collective worship singing.
              </div>

              {/* Form for email, diocese, and two checkboxes for Privacy Policy and CCLI Lincense */}
              <Form className="futura-font">
                <Form.Group className="mb-3" controlId="formBasicSchoolEmail">
                  <Form.Control type="email" placeholder="Email address" />
                  </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicSchoolName">
                  <Form.Control type="email" placeholder="Enter school name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicRegisterCheckbox">
  <div className="checkbox-wrapper">
    <Form.Check
      type="checkbox"
      id="registerCheckbox"
    />
    <Form.Label htmlFor="registerCheckbox" id="registerCheckbox" className="checkbox-label">
      <span className="blue-futura">
        Agree to our <a href="/privacy">privacy policy</a>.
      </span>
    </Form.Label>
  </div>
</Form.Group>

                </Form>
                <div className="container pt-0 mt-5">
  <div className="row homepage-content">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-10 col-lg-10 px-0 mt-5 mb-5">
      <div className="download-button">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <Button variant="primary border-0" className="" onClick={register}><span className="button-text">REGISTER!</span></Button>
        </div>
    </div>
    <div className="col"></div>
  </div>
</div>
            </div>
            <div className="col"></div>
        </div>
        

<FooterText />
     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>

    </>
  );
}

export default Sing;