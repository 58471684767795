import React from 'react';
import Button from 'react-bootstrap/Button';
import './css/LandingPage.css';
import {useState , useEffect} from 'react';
// Import ../images/FullLogoStrap.png as Bannerimage
import BannerImage from '../images/FullLogoStrap.png';
import MobileBannerImage from '../images/cleanLogo.png';
import RaysOnly from '../images/RaysOnly.png';
import PraiseNav from './PraiseNav';
import FooterImage from '../images/FooterImage.png';
import FooterText from './FooterText';
// ../Rich Diversity_Home Gfx.png
// import HomepageImage from '../images/Rich Diversity_Home Gfx.png';
// Import Photo montage.png
// import PhotoMontage from '../images/Photo montage.jpg';
// import 5 stars as 5stars
import FiveStars from '../images/5 Stars.png';
function LandingPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bannerImage = windowWidth < 850 ? MobileBannerImage : BannerImage;
  return (
    <>
      <div className="gradient-background homescreen-stars-container"></div>
      <div className="navbar-container">
        
      <PraiseNav />
      </div>
      
      <a href="/" className="text-decoration-none">
      <img src={bannerImage} alt="Praise-along Logo" className="banner-image mt-5 mb-5" />

      </a>
      <div className="row homepage-content pb-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
    <span className="red-text"><b>Praise-along</b></span> is a digital music service providing primary schools with free monthly songs, produced especially for collective worship assemblies. Registered schools can stream our monthly song videos free of charge and also have the option to purchase sheet music and backing tracks to keep and collect!<br/> <a href="/comingup">Find out what's coming up...</a>

   
     </div>
     <div className="col " ></div>
   </div>  
   <div className="row homepage-content pb-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0" >
    {/* <img src={HomepageImage} alt="Homepage" className="landing-page-image" /> */}
     <img alt = "pray for peace" src="https://praisealongpublic.blob.core.windows.net/praisealongpublic/What Love Is.png"  width="100%" height="auto" className="mb-5" />
      </div>
      <div className="col">

      </div>
    </div>
    <div className="row homepage-content trans-white-bg pt-5 pb-5">
  <div className="col"></div>
  <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
    <span className="landing-header-large"><b>THIS MONTH</b></span>
    <br /><br />
    <span className="red-text"><b>Praise-along</b></span> with the children from Hapton Primary School this February! Our popular song “What Love Is” is based on the famous Bible passage from 1 Corinthians, Chapter 13. It can help us to identify the true characteristics of love in the light of healthy relationships with friends, families and other people in our lives.
    <br /><br />
    50% of all proceeds generated by the song this month will be donated to Kaleidoscopic UK; a charity that offers support services to children and families who have been exposed to domestic abuse and provides specialist training for anyone working with or alongside them. 
    <br/><br />
  </div>
  <div className="col"></div>
</div>
   <div className="row homepage-content mt-5">
    <div className="col"></div>
    <div className="col-10 col-sm-10 col-md-5 col-lg-4 mx-auto px-0">
    Register your school to receive our FREE monthly song videos with the 
added option to buy backing tracks and sheet music to keep and collect for 
use in collective worship.<br/><br/>
      <div className="download-button mt-5">
        <img src={RaysOnly} alt="Background" className="background-image" />
        <a href="/sing" className="text-decoration-none">
        <Button variant="primary border-0" className=""><span className="button-text">Register</span></Button>
          
        </a>
      </div>
    </div>
    <div className="col"></div>
  </div>
     

     
  <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"What an amazing project and philosophy!"
 
      </div>
      <div className="container praise-along-subslogan">
      St Thomas of Canterbury Catholic Primary School, Guildford
      </div>
      
      
      <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"Everyone has loved all of your songs so far!"
 
      </div>
      <div className="container praise-along-subslogan">
      Houghton CofE School, Carlisle
      </div>
      <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"The children thoroughly enjoyed it, as did we!"
 
      </div>
      <div className="container praise-along-subslogan">
      Brunshaw Primary School, Burnley
      </div>
      <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"We really enjoy our monthly songs!"
 
      </div>
      <div className="container praise-along-subslogan">
      Kilby St Mary's CofE Primary School, Leicester
      </div>
      <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"Our children love singing the Praise-along songs"
 
      </div>
      <div className="container praise-along-subslogan">
      St Cuthbert's Catholic Primary School, Wigton
      </div>
      <div className="container">
   {/* FiveStars */}
   <div className="row homepage-content pt-5 mt-5">
     <div className="col"></div>
     <div className="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto px-0" >
     {/* PhotoMontage */}
      <img src={FiveStars} alt="stars" className="five-stars mb-3" />
   
     </div>
     <div className="col " ></div>
   </div>  
   </div>
     <div className="container praise-along-slogan">
"What a fantastic project, engaging for all students!"
 
      </div>
      <div className="container praise-along-subslogan">
      Hapton CE Methodist Primary School, Hapton
      </div>
      
   
      
   
      



<FooterText />

     
<div className="footer-image">
  <img src={FooterImage} alt="Footer" className="full-width-image" />
</div>
    </>
  );
}

export default LandingPage;
